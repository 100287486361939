<template>
  <div id="order-view-actions-spec-awaiting-quotation">
    <div v-if="isQuoteOwner">
      <b-button id="back-to-catalog" v-ripple.400="'rgba(186, 191, 199, 0.15)'" class="mb-75" block target="_blank" @click.prevent="$router.push({ name: 'services' })">
        <feather-icon icon="SkipBackIcon" size="20" class="align-middle" />
        <span class="ml-25 align-middle"> {{ $t('order.back_to_catalog') }} </span>
      </b-button>
      <OrderViewActionSaveAndSend :disabled="disabled" />
      <OrderViewActionCancelOrRefuse :disabled="disabled" btn-icon="MinusCircleIcon" :btn-text="$t('action.decline')" :refuse-title="$t('order.refusal')" />
      <b-button
        id="order-view-action-awaiting-for-quotation-save-draft"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        class="mb-75"
        block
        :disabled="disabled || quoteTouched"
        @click.prevent="saveQuote()"
      >
        <feather-icon icon="SaveIcon" size="20" class="align-middle" />
        <span class="ml-25 align-middle"> {{ $t('action.save_draft') }} </span>
      </b-button>
    </div>
    <div v-else>
      <div v-if="quoteSelected.quote.lines">
        <OrderViewActionCancelAndEdit
          v-if="enableRecallCart()"
          :disabled="disabled"
          :btn-text="$t('action.edit_request')"
          :confirm-title="$t('action.edit_request')"
          :confirm-question="$t('order.quotation_request.edit_question')"
          :confirm-text="$t('order.quotation_request.edit_text')"
        />
        <OrderViewActionCancelOrRefuse :disabled="disabled" btn-icon="XCircleIcon" :btn-text="$t('action.cancel')" :refuse-title="$t('order.refusal')" />
      </div>
    </div>
  </div>
</template>
<script>
import { patchQuoteRequest } from '@/request/globalApi/requests/quoteRequests'

import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import OrderViewActionSaveAndSend from '@/views/order/OrderViewActionSaveAndSend.vue'

export default {
  name: 'OrderViewActionsSpecAwaitingQuotation',

  components: {
    OrderViewActionCancelAndEdit: () => import('@/views/order/OrderViewActionCancelAndEdit.vue'),
    OrderViewActionCancelOrRefuse: () => import('@/views/order/OrderViewActionCancelOrRefuse.vue'),
    OrderViewActionSaveAndSend,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'quoteTouched', 'isQuoteOwner']),
    ...mapFields('quote', ['quoteLoading']),
  },

  methods: {
    ...mapActions('quote', ['fetchQuote']),

    saveQuote() {
      this.quoteLoading = true
      patchQuoteRequest(this.quoteSelected.quote, this.$store.getters['quote/quoteTitleAndId'])
        .then(() => {
          this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
            this.quoteLoading = false
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },

    enableRecallCart() {
      return this.quoteSelected.quote.lines.filter(line => line.lineClass !== 'FuelQuoteLine' && line.lineClass !== 'HelicopterQuoteLine').length
    },
  },
}
</script>
